import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  //Landing
  {
    path: '/',
    component: () => import('../components/layouts/main.layout.vue'),
    children: [
      {
        path: '',
        name: 'landing',
        redirect: { name: 'home' },
      },
      {
        path: '/home',
        name: 'home',
        component: () => import('../pages/index.page.vue'),
      },

      {
        path: '/camp-maps',
        name: 'camp-maps',
        component: () => import('../pages/camp-maps.page.vue'),
      },
      {
        path: '/documents',
        name: 'documents',
        component: () => import('../pages/documents.page.vue'),
      },

      {
        path: '/documentsviewer/:name/:id',
        name: 'documentsviewer',
        component: () => import('../pages/documentsviewer.page.vue'),
      },

      {
        path: '/clusters',
        name: 'clusters',
        component: () => import('../pages/clusters.page.vue'),
      },
      {
        path: '/clusters/:id/:district',
        name: 'clusters-id',
        component: () => import('../pages/clusters.page.vue'),
      },
      {
        path: '/infographics',
        name: 'infograohucs',
        component: () => import('../pages/infographics.page.vue'),
      },

      {
        path: '/preliminaryreports',
        name: 'preliminaryreports',
        component: () => import('../pages/preliminary.page.vue'),
      },
      {
        path: '/template',
        name: 'infographic-template',
        component: () => import('../pages/template.page.vue'),
      },
      {
        path: '/events',
        name: 'events',
        component: () => import('../pages/events.page.vue'),
      },
      {
        path: '/districts',
        name: 'districtsprofile',
        component: () => import('../pages/district-profiles.page.vue'),
      },
      {
        path: '/profile/',
        name: 'districtprofile',
        component: () => import('../pages/district-profile.page.vue'),
      },
      {
        path: '/about',
        name: 'about',
        component: () => import('../pages/about.page.vue'),
      },
      {
        path: '/privacy',
        name: 'privacy',
        component: () => import('../pages/privacy.page.vue'),
      },
      {
        path: '/terms',
        name: 'terms',
        component: () => import('../pages/terms.page.vue'),
      },
      {
        path: 'user_manual',
        name: 'user_manual',
        component: () => import('../pages/user-manual.page.vue'),
      },
      // {
      //   path: "",
      //   name: 'landing',
      //   redirect: { name: 'home' },
      // },
      // {
      //   name: 'home',
      //   path: "/home",
      //   component: () => import('../pages/index.page.vue'),
      // },
    ],
  },
  //Portal
  {
    path: '/portal',
    component: () => import('../components/layouts/plain.layout.vue'),
    children: [
      {
        path: '',
        name: 'portal-home',
        redirect: { name: 'portal-signin' },
      },
      {
        name: 'portal-signin',
        path: '/portal/signin',
        component: () => import('../pages/portal/signin.page.vue'),
      },
      {
        name: 'portal-signup',
        path: '/portal/signup',
        component: () => import('../pages/portal/signup.page.vue'),
      },
      {
        name: 'portal-forgot-password',
        path: '/portal/forgot-password',
        component: () => import('../pages/portal/forgot-password.page.vue'),
      },
    ],
  },
  //Admin
  {
    path: '/admin',
    name: 'admin',
    component: () => import('../components/layouts/admin.layout.vue'),
    children: [
      //Dashboard
      {
        path: '',
        name: 'admin-home',
        redirect: { name: 'admin-dashboard' },
      },
      {
        path: '/admin/dashboard',
        name: 'admin-dashboard',
        component: () => import('../pages/admin/dashboard/index.page.vue'),
      },
      //Users
      {
        path: '/admin/users',
        name: 'admin-users',
        component: () => import('../pages/admin/users/index.page.vue'),
      },
      {
        path: '/admin/users/manage/:id',
        name: 'admin-manage-user',
        component: () => import('../pages/admin/users/manage.page.vue'),
      },
      //Documents
      {
        path: '/admin/documents',
        name: 'admin-documents',
        component: () => import('../pages/admin/documents/index.page.vue'),
      },
      {
        path: '/admin/documents/manage/:id',
        name: 'admin-manage-document',
        component: () => import('../pages/admin/documents/manage.page.vue'),
      },
      //Contacts
      {
        path: '/admin/contacts',
        name: 'admin-contacts',
        component: () => import('../pages/admin/contacts/index.page.vue'),
      },
      {
        path: '/admin/contacts/manage/:id',
        name: 'admin-manage-contact',
        component: () => import('../pages/admin/contacts/manage.page.vue'),
      },
      //Profiles
      {
        path: '/admin/profiles',
        name: 'admin-profiles',
        component: () => import('../pages/admin/profiles/index.page.vue'),
      },
      {
        path: '/admin/profiles/import',
        name: 'admin-profiles-import',
        component: () =>
          import('../components/pages/districtProfiles/import.component.vue'),
      },
      {
        path: '/admin/profiles/manage/:id',
        name: 'admin-manage-profile',
        component: () => import('../pages/admin/profiles/manage.page.vue'),
      },
      {
        path: '/admin/disasterprofiles',
        name: 'admin-profiles-disaster',
        component: () =>
          import('../pages/admin/disasterProfile/index.page.vue'),
      },
      {
        path: '/admin/disasterprofiles/manage/:id',
        name: 'admin-manage-disaster-profile',
        component: () =>
          import('../pages/admin/disasterProfile/manage.page.vue'),
      },
      //Clusters
      {
        path: '/admin/clusters',
        name: 'admin-clusters',
        component: () => import('../pages/admin/clusters/index.page.vue'),
      },
      {
        path: '/admin/clusters/manage/:id',
        name: 'admin-manage-cluster',
        component: () => import('../pages/admin/clusters/manage.page.vue'),
      },

      {
        path: '/admin/partners',
        name: 'admin-partners',
        component: () => import('../pages/admin/partners/index.page.vue'),
      },
      {
        path: '/admin/partners/manage/:id',
        name: 'admin-manage-partners',
        component: () => import('../pages/admin/partners/manage.page.vue'),
      },
      {
        path: '/admin/cluster/links',
        name: 'admin-cluster-links',
        component: () =>
          import('../components/pages/cluster/url.component.vue'),
      },
      {
        path: '/admin/help',
        name: 'help',
        component: () => import('../components/layouts/help-center.vue'),
      },
    ],
    beforeEnter: (to, from, next) => {
      let role = JSON.parse(sessionStorage.getItem('RLE'));
      if (role != null && typeof role.name != 'undefined') {
        if (role.name == 'admin') {
          next();
        } else {
          next({ name: 'portal-signin' });
        }
      } else {
        next({ name: 'portal-signin' });
      }
    },
  },

  //Manager
  {
    path: '/manager',
    name: 'manager',
    component: () => import('../components/layouts/manager.layout.vue'),
    children: [
      //Dashboard
      {
        path: '',
        name: 'manager-home',
        redirect: { name: 'manager-dashboard' },
      },

      // profile
      {
        path: '/manager/profile',
        name: 'manager-profile',
        component: () => import('../pages/manager/profile/index.page.vue'),
      },

      {
        path: '/manager/profile/manage/:id',
        name: 'manager-manage-profile',
        component: () => import('../pages/manager/profile/manage.page.vue'),
      },
      // ///////

      // demography
      {
        path: '/manager/demography',
        name: 'manager-demography',
        component: () => import('../pages/manager/demography/index.page.vue'),
      },
      {
        path: '/manager/demography/manage/:id',
        name: 'user-manage-demography',
        component: () => import('../pages/manager/demography/manage.page.vue'),
      },
      // //////

      // dashboard
      {
        path: '/manager/dashboard',
        name: 'manager-dashboard',
        component: () => import('../pages/manager/dashboard/index.page.vue'),
      },
      {
        path: '/manager/donations',
        name: 'manager-donations',
        component: () => import('../pages/manager/donations/index.page.vue'),
      },
      {
        path: '/manager/snapshot',
        name: 'manager-snapshot',
        component: () => import('../pages/manager/snapshot/index.page.vue'),
      },
      //Users
      {
        path: '/manager/users',
        name: 'manager-users',
        component: () => import('../pages/manager/users/index.page.vue'),
      },
      {
        path: '/manager/users/manage/:id',
        name: 'manager-manage-user',
        component: () => import('../pages/manager/users/manage.page.vue'),
      },

      //Documents
      {
        path: '/manager/documents',
        name: 'manager-documents',
        component: () => import('../pages/manager/documents/index.page.vue'),
      },
      {
        path: '/manager/documents/manage/:id',
        name: 'manager-manage-document',
        component: () => import('../pages/manager/documents/manage.page.vue'),
      },

      {
        path: '/manager/donations/manage/:id',
        name: 'manager-manage-donation',
        component: () => import('../pages/manager/donations/manage.page.vue'),
      },
      //Contacts
      {
        path: '/manager/contacts',
        name: 'manager-contacts',
        component: () => import('../pages/manager/contacts/index.page.vue'),
      },
      {
        path: '/manager/contacts/manage/:id',
        name: 'manager-manage-contact',
        component: () => import('../pages/manager/contacts/manage.page.vue'),
      },
      {
        path: '/user/contacts/manage/:id',
        name: 'user-manage-contact',
        component: () => import('../pages/user/contacts/manage.page.vue'),
      },
      //Help-Center
      {
        path: '/manager/help-center',
        name: 'help-center',
        component: () => import('../components/layouts/help-center.vue'),
      },
    ],
    beforeEnter: (to, from, next) => {
      let role = JSON.parse(sessionStorage.getItem('RLE'));
      if (role != null && typeof role.name != 'undefined') {
        if (role.name == 'manager') {
          next();
        } else {
          next({ name: 'portal-signin' });
        }
      } else {
        next({ name: 'portal-signin' });
      }
    },
  },
  // Supervisor
  {
    path: '/supervisor',
    name: 'supervisor',
    component: () => import('../components/layouts/supervisor.layout.vue'),
    children: [
      //Dashboard
      {
        path: '',
        name: 'supervisor-home',
        redirect: { name: 'supervisor-dashboard' },
      },
      {
        path: '/supervisor/dashboard',
        name: 'supervisor-dashboard',
        component: () => import('../pages/supervisor/dashboard/index.page.vue'),
      },
      //Users
      {
        path: '/supervisor/users',
        name: 'supervisor-users',
        component: () => import('../pages/supervisor/users/index.page.vue'),
      },
      {
        path: '/supervisor/users/manage/:id',
        name: 'supervisor-manage-user',
        component: () => import('../pages/supervisor/users/manage.page.vue'),
      },
      //Documents
      {
        path: '/supervisor/documents',
        name: 'supervisor-documents',
        component: () => import('../pages/supervisor/documents/index.page.vue'),
      },
      {
        path: '/supervisor/documents/manage/:id',
        name: 'supervisor-manage-document',
        component: () =>
          import('../pages/supervisor/documents/manage.page.vue'),
      },
      //Contacts
      {
        path: '/supervisor/contacts',
        name: 'supervisor-contacts',
        component: () => import('../pages/supervisor/contacts/index.page.vue'),
      },
      {
        path: '/supervisor/contacts/manage/:id',
        name: 'supervisor-manage-contact',
        component: () => import('../pages/supervisor/contacts/manage.page.vue'),
      },
      //Help-Center
      {
        path: '/supervisor/help-center',
        name: 'help-center',
        component: () => import('../components/layouts/help-center.vue'),
      },
    ],
    beforeEnter: (to, from, next) => {
      let role = JSON.parse(sessionStorage.getItem('RLE'));
      if (role != null && typeof role.name != 'undefined') {
        if (role.name == 'supervisor') {
          next();
        } else {
          next({ name: 'portal-signin' });
        }
      } else {
        next({ name: 'portal-signin' });
      }
    },
  },
  {
    path: '/user',
    component: () => import('../components/layouts/user.layout.vue'),
    children: [
      {
        path: '',
        redirect: { name: 'user-dashboard' },
      },
      {
        path: 'dashboard',
        name: 'user-dashboard',
        component: import('../pages/user/dashboard/index.page.vue'),
      },
      {
        path: '/user/donations',
        name: 'user-donations',
        component: () => import('../pages/user/donations/index.page.vue'),
      },
      {
        path: '/user/profile',
        name: 'user-profile',
        component: () => import('../pages/user/profile/index.page.vue'),
      },
      // ////////////////////////////
      // {
      //   path: '/manager/profile',
      //   name: 'user-profile',
      //   component: () => import('../pages/manager/profile/index.page.vue'),
      // },
      /////////////////////////////////////////
      //Users
      // {
      //   path: '/user/clusters',
      //   name: 'user-clusters',
      //   component: () => import('../pages/user/users/index.page.vue'),
      // },
      {
        path: '/user/users/manage/:id',
        name: 'user-manage-user',
        component: () => import('../pages/user/users/manage.page.vue'),
      },
      //Documents
      {
        path: '/user/documents',
        name: 'user-documents',
        component: () => import('../pages/user/documents/index.page.vue'),
      },
      {
        path: '/user/documents/manage/:id',
        name: 'user-manage-document',
        component: () => import('../pages/user/documents/manage.page.vue'),
      },

      {
        path: '/user/donations/manage/:id',
        name: 'user-manage-donation',
        component: () => import('../pages/user/donations/manage.page.vue'),
      },
      {
        path: '/user/profile/manage/:id',
        name: 'user-manage-profile',
        component: () => import('../pages/user/profile/manage.page.vue'),
      },
      //Contacts
      {
        path: '/user/contacts',
        name: 'user-contacts',
        component: () => import('../pages/user/contacts/index.page.vue'),
      },
      {
        path: '/user/contacts/manage/:id',
        name: 'user-manage-contact',
        component: () => import('../pages/user/contacts/manage.page.vue'),
      },

      //Demography
      {
        path: '/user/demography',
        name: 'user-demography',
        component: () => import('../pages/user/demography/index.page.vue'),
      },
      {
        path: '/user/demography/manage/:id',
        name: 'user-manage-demography',
        component: () => import('../pages/user/demography/manage.page.vue'),
      },
      //Help-Center
      {
        path: '/user/help-center',
        name: 'help-center',
        component: () => import('../components/layouts/help-center.vue'),
      },
    ],
  },
  //cluster
  {
    path: '/cluster',
    component: () => import('../components/layouts/cluster.layout.vue'),
    children: [
      {
        path: '',
        redirect: { name: 'cluster-dashboard' },
      },
      {
        path: 'dashboard',
        name: 'cluster-dashboard',
        component: import('../pages/cluster/dashboard/index.page.vue'),
      },
      {
        path: '/cluster/donations',
        name: 'cluster-donations',
        component: () => import('../pages/cluster/donations/index.page.vue'),
      },
      {
        path: '/cluster/profile',
        name: 'cluster-profile',
        component: () => import('../pages/cluster/profile/index.page.vue'),
      },
      //Users
      {
        path: '/cluster/clusters',
        name: 'cluster-clusters',
        component: () => import('../pages/cluster/users/index.page.vue'),
      },
      {
        path: '/cluster/users/manage/:id',
        name: 'cluster-manage-user',
        component: () => import('../pages/cluster/users/manage.page.vue'),
      },
      {
        path: '/cluster/profile/manage/:id',
        name: 'cluster-manage-profile',
        component: () => import('../pages/cluster/profile/manage.page.vue'),
      },
      //Documents
      {
        path: '/cluster/documents',
        name: 'cluster-documents',
        component: () => import('../pages/cluster/documents/index.page.vue'),
      },
      {
        path: '/cluster/documents/manage/:id',
        name: 'cluster-manage-document',
        component: () => import('../pages/cluster/documents/manage.page.vue'),
      },

      {
        path: '/cluster/donations/manage/:id',
        name: 'cluster-manage-donation',
        component: () => import('../pages/cluster/donations/manage.page.vue'),
      },
      //Contacts
      {
        path: '/cluster/contacts',
        name: 'cluster-contacts',
        component: () => import('../pages/cluster/contacts/index.page.vue'),
      },
      {
        path: '/cluster/contacts/manage/:id',
        name: 'cluster-manage-contact',
        component: () => import('../pages/cluster/contacts/manage.page.vue'),
      },

      //Demography
      //  {
      //   path: "/cluster/demography",
      //   name: 'cluster-demography',
      //   component: () => import('../pages/cluster/demography/index.page.vue'),
      // },
      // {
      //   path: "/cluster/demography/manage/:id",
      //   name: 'cluster-manage-demography',
      //   component: () => import('../pages/cluster/demography/manage.page.vue'),
      // },
      //Help-Center
      {
        path: '/cluster/help-center',
        name: 'help-center',
        component: () => import('../components/layouts/help-center.vue'),
      },
    ],
  },
  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('../pages/Errors/404.error.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
