<template>
  <router-view/>
</template>
<script setup>
import { inject, ref, watch, reactive, onMounted, toRefs } from "vue";

onMounted(() => {
 
});
</script>

<style >
#app {
  height: 100vh;
}
</style>